<template>
    <BaseSection
        width="page"
        class="section--highlight-links"
    >
        <HighlightLinks :links="links">
            <template v-slot:more-info>
                <MetaLine direction="vertical" class="highlight-links__read-more">
                    {{ $t('more-info') }}
                </MetaLine>
            </template>
        </HighlightLinks>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    highlightLinks: {
        type: Array,
        required: true,
        default: null
    }
});

const links = computed(() => {
    if (!props.highlightLinks || !props.highlightLinks.length) {
        return false;
    }

    return props.highlightLinks.map(link => link.link);
});
</script>
